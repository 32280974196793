.filter__widget select {
    padding: 8px 20px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    border-radius: 5px;
    background: var(--primary-color);
    color: #fff;
}

.filter__widget select:focus {
    outline: none !important;
}

.filter__widget select option {
    font-size: 0.9rem;
}

.search__box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding-right: 12px;
    padding-left: 2px;
    cursor: pointer;
}

.search__box input {
    width: 100%;
    border: none;
    outline: none;
    padding: 8px 10px;
}

.search__box span {
    color: var(--primary-color);
}

@media only screen and (max-width:768px) {
    .filter__widget {
        margin-bottom: 30px;
    }

    .filter__widget select {
        padding: 7px 20px;
        font-size: 0.9rem;
    }
}